/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import About from '../page-components/Entrepreneurship/About';
import CentreFormation from '../page-components/Entrepreneurship/CentreFormation';
import Collaboration from '../page-components/Entrepreneurship/Collaboration';
import ProgramDna from '../page-components/Entrepreneurship/ProgramDna';
import Program from '../page-components/Entrepreneurship/Program';
import Slogan from '../page-components/Entrepreneurship/Slogan';
import IncubationProgram from '../page-components/Entrepreneurship/IncubationProgram';
import Inscription from '../page-components/Entrepreneurship/Inscription';
/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Entrepreneurship() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      <Slogan />
      <IncubationProgram />
      <About />
      <CentreFormation />
      <Collaboration />
      <ProgramDna />
      <Program />
      <Inscription />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Entrepreneurship", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Entrepreneurship;
