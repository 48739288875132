/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// UI lib comoponents
import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// assets
import Image from '../../../shared/Image';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Collaboration() {
  /* ******************************** CONSTANTS ******************************* */

  /* ******************************** RENDERING ******************************* */
  // Localization
  const { t } = useTranslation();
  const { title, list } = t('collaboration', {
    returnObjects: true,
  });

  function ListItems() {
    return list.map(({ id, src }) => (
      <div className="collaboration-list-img-div">
        <Image
          key={id}
          className="collaboration-list-img"
          src={src}
          alt="collaboration"
        />
      </div>
    ));
  }
  return (
    <>
      <div className="collaboration-img">
        <Image src="section-image.png" alt="Section Image" />
      </div>
      <Container className="collaboration">
        <div className="collaboration-title">{title}</div>
        <div className="collaboration-list">
          <ListItems />
        </div>
      </Container>
    </>
  );
}

Collaboration.propTypes = {};

Collaboration.defaultProps = {};

export default Collaboration;
