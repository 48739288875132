/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CentreFormation() {
  const { t } = useTranslation();
  const { title, description, button } = t('centreFormation', {
    returnObjects: true,
  });
  return (
    <Container className="centre-formation" fluid>
      <Container>
        <Row>
          <Col lg={6} md={12} className="centre-formation-title">
            {title}
          </Col>
          <Col className="centre-formation-description">
            {description}
            <Link style={{ textDecoration: 'none' }} to="/center">
              <button
                className="centre-formation-description-button"
                type="button"
              >
                {button}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

CentreFormation.propTypes = {};

CentreFormation.defaultProps = {};

export default CentreFormation;
