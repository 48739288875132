/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// assets
import arrow from '../../../images/shared-images/arrow.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Program() {
  /* ******************************** CONSTANTS ******************************* */
  // Localization
  const { t } = useTranslation();
  const { title, list } = t('program', {
    returnObjects: true,
  });

  function ListItems() {
    return list.map(({ id, titlelist, description }) => (
      <div key={id} className="program-com-list">
        <img className="program-com-list-img" src={arrow} alt="Program" />
        <div className="program-com-list-block">
          <div className="program-com-list-block-title">{titlelist}</div>
          <div className="program-com-list-block-description">
            {description}
          </div>
        </div>
      </div>
    ));
  }
  return (
    <Container className="program-com" justify="center" fluid>
      <Container>
        <div className="program-com-title">{title}</div>
        <div className="program-com-desc">
          <ListItems />
        </div>
      </Container>
    </Container>
  );
}

Program.propTypes = {};

Program.defaultProps = {};

export default Program;
