/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// assets
import Image from '../../../shared/Image';
import arrow from '../../../images/shared-images/arrow.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function About() {
  const { t } = useTranslation();
  const { title, list } = t('aboutEntrepreneurship', {
    returnObjects: true,
  });
  function ListItems() {
    return list.map(({ id, nbr, mois, desc }) => (
      <li key={id} className="about1-entrepreneurship-list-item">
        <div className="about1-entrepreneurship-list-img">
          <img src={arrow} alt="arrow" />
        </div>
        <div className="about1-entrepreneurship-list-item-1">{nbr}</div>
        <div className="about1-entrepreneurship-list-item-2">{mois}</div>
        <div className="about1-entrepreneurship-list-item-3">{desc}</div>
      </li>
    ));
  }
  return (
    <Container justify="center" className="about1-entrepreneurship">
      <Row>
        <Col lg={6} sm={12}>
          <Image src="history.jpg" />
        </Col>
        <Col className="about1-entrepreneurship-list" lg={6} sm={12}>
          <div className="about1-entrepreneurship-list-title">{title}</div>
          <ul>
            <ListItems />
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

About.propTypes = {};

About.defaultProps = {};

export default About;
