/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import Image from '../../../shared/Image';
import FormButton from '../../../shared/UIKit/FormButton';

// Assets
import trueicon from '../../../images/AboutUs/check.svg';
// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Inscription() {
  /* ******************************** CONSTANTS ******************************* */
  const { t } = useTranslation();
  const {
    title,
    description1,
    description2,
    description3,
    description4,
    description5,
    list,
    button,
  } = t('inscription', {
    returnObjects: true,
  });
  function ListItems() {
    return list.map(({ id, item }) => (
      <li key={id} className="inscription-containter-list-item">
        <img src={trueicon} alt="true" className="true-img-1" />
        {item}
      </li>
    ));
  }

  return (
    <Container className="inscription" justify="center" fluid>
      <Row>
        <Col lg={6} md={12}>
          <div className="inscription-img">
            <Image src="GroupElife.png" alt="Elife" />
          </div>
        </Col>
        <Col lg={6} md={12}>
          <div className="inscription-group">
            <div className="inscription-group-title">{title}</div>
            <p className="inscription-group-dscription">{description1}</p>
            <p className="inscription-group-dscription">{description2}</p>
            <p className="inscription-group-dscription">{description3}</p>
            <p className="inscription-group-dscription">{description4}</p>
            <ListItems />
            <p className="inscription-group-dscription">{description5}</p>
            <FormButton
              text={button}
              navigationlink="http://docs.google.com/.../1Thdy6mOgYFU.../edit..."
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Inscription.propTypes = {};

Inscription.defaultProps = {};

export default Inscription;
