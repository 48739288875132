/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// assets
import Image from '../../../shared/Image';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function ProgramDna() {
  /* ******************************** CONSTANTS ******************************* */

  // Localization
  const { t } = useTranslation();
  const { title, list } = t('programDna', {
    returnObjects: true,
  });

  function ListItems() {
    return list.map(({ id, titleList, description }) => (
      <div key={id} className="program-dna-list">
        <div className="program-dna-list-title">{titleList}</div>
        <div className="program-dna-list-description">{description}</div>
      </div>
    ));
  }
  return (
    <Container className="program-dna">
      <Row>
        <Col lg={7} md={12}>
          <div className="program-dna-title">{title}</div>
          <div className="program-dna-desc">
            <ListItems />
          </div>
        </Col>
        <Col justify="center" lg={5} md={12} className="program-dna-img">
          <Image src="history.jpg" alt="Elife" />
        </Col>
      </Row>
    </Container>
  );
}

ProgramDna.propTypes = {};

ProgramDna.defaultProps = {};

export default ProgramDna;
