/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import Image from '../../../shared/Image';
// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function IncubationProgram() {
  /* ******************************** CONSTANTS ******************************* */
  // Localization
  const { t } = useTranslation();
  const { title, description } = t('incubationProgram', {
    returnObjects: true,
  });
  return (
    <Container fluid>
      <Container className="incubation-program">
        <Row>
          <Col lg={5} sm={12}>
            <div className="incubation-program-title">{title}</div>
          </Col>
          <Col lg={7} sm={12}>
            <div className="incubation-program-description">{description}</div>
          </Col>
        </Row>
      </Container>
      <div className="incubation-program-img">
        <Image src="Group.png" alt="Elife" />
      </div>
    </Container>
  );
}

IncubationProgram.propTypes = {};

IncubationProgram.defaultProps = {};

export default IncubationProgram;
